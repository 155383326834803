import { observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { api } from 'app/utils/api'
import { getClientReferenceId } from 'app/utils/trackdesk/client'
import { useUser } from 'app/utils/useUser'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { toast } from 'sonner'

import { useBillingScreenTranslation } from '../hooks/useBillingScreenTranslation'

const open$ = observable(false)

export const toggleRedeemCouponModal = () => {
  open$.set(!open$.get())
}

export const RedeemCouponModal = observer(() => {
  const [couponCode, setCouponCode] = useState('')
  const [error, setError] = useState('')
  const { t } = useBillingScreenTranslation()

  const onRedeemCoupon = api.ecommerce.redeemCoupon.useMutation()
  const { updateProfile } = useUser()
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!couponCode.trim()) {
      setError('Coupon code is required')
      return
    }
    // Logic for redeeming the coupon
    onRedeemCoupon.mutateAsync({ code: couponCode.trim(), cid: getClientReferenceId() }).then(
      () => {
        // Reset form
        handleClose()
        toast.success(t('redeemCoupon.success'))
        updateProfile()
      },
      (err) => {
        toast.error(err.message)
        setError(err.message)
      }
    )
  }

  const handleClose = () => {
    setCouponCode('')
    setError('')
    open$.set(false)
  }

  return (
    <Modal isOpen={open$.get()} onClose={handleClose}>
      <div className="bg-coal-500 min-w-[320px] max-w-md rounded-lg px-8 py-12">
        <h2 className="mb-4 mt-6 text-2xl font-bold text-gray-900 md:text-3xl">
          {t('redeemCoupon.title')}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder={t('redeemCoupon.input')}
              className="w-full rounded-md border border-gray-700 bg-gray-900 px-3 py-2 text-gray-100 placeholder-gray-500 transition-all duration-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            />
          </div>
          <AnimatePresence>
            {error && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-sm text-red-400"
              >
                {error}
              </motion.p>
            )}
          </AnimatePresence>
          <Button
            type="submit"
            className="w-full rounded-md bg-blue-600 px-4 py-2 text-gray-100 transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            showArrow={false}
          >
            {t('redeemCoupon.redeem')}
          </Button>
        </form>
      </div>
    </Modal>
  )
})
